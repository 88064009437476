import {
  ImagePreview,
  ImageNext,
} from "./styles";
import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './style.css';
import { ButtonGroup } from "../../pages/Company/styles";

// One item component
// selected prop will be passed
const MenuItem = ({ text, selected, id }) => {
  return (
    <ButtonGroup
      //className="menu-item"
      id={id}
      isActive={selected}
    >
      {text}
    </ButtonGroup>
  );
};

// All items component
// Important! add unique key
export const Menu = (list) => list.map(el => {
  const { id } = el;
  const { name } = el;
  const { selected } = el;

  return (
    <MenuItem
      text={name}
      key={"HorizontalScrolling" + id}
      id={"HorizontalScrolling" + id}
      selected={selected ?? false}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};

//const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
//const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

const ArrowLeft = <ImagePreview />;
const ArrowRight = <ImageNext />;

class HorizontalScrolling extends Component {
  state = {
    selected: ""
  };

  onSelect = key => {
    this.setState({ selected: key });
    this.onSelected(key);
  }

  constructor(props) {
    super(props);
    this.list = props.list;
    this.onSelected = props.onSelected;
  }

  render() {
    const { selected } = this.state;
    // Create menu from items
    const menu = Menu(this.list, selected);

    return (
      <div>
        <ScrollMenu
          data={menu}
          //arrowLeft={ArrowLeft}
          //arrowRight={ArrowRight}
          selected={selected}
          onSelect={this.onSelect}
          alignCenter={false}
        />
      </div>
    );
  }
}

export default HorizontalScrolling