import React, { Component } from "react";
//import Modal from "react-modal";

import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  FormTitle,
  Section,
  ButtonContainer,
  ButtonCancel,
  ButtonSecondary,
  Label,
} from "./styles";

interface ShowModalProps {
  isOpen: boolean;
  //image_url?: string;
  title: string;
  label: string;
  document?: JSX.Element;
  footer?: JSX.Element;
  labelPositivo?: string;
  onPositivo?: React.MouseEventHandler<HTMLButtonElement>;
  labelNeutro?: string;
  onNeutro?: React.MouseEventHandler<HTMLButtonElement>;
  labelNegativo?: string;
  onNegativo?: React.MouseEventHandler<HTMLButtonElement>;
  onRequestClose?(event: React.MouseEvent | React.KeyboardEvent): void;
  centered?: boolean;
  size?: "sm" | "lg" | "xl";
  isFooter?: boolean;
}

export default function ShowModal(props: ShowModalProps) {
  return (
    <Modal
      show={props.isOpen}
      onHide={props.onRequestClose}
      size={props.size !== undefined ? props.size : "lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered={props.centered ?? true}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <FormTitle>{props.title}</FormTitle>
      </Modal.Header>
      <Modal.Body>
        {props.label !== "" ? <Label>{props.label}</Label> : ""}
        {props.document}
      </Modal.Body>
      {props.isFooter === undefined || props.isFooter ? (
        <Modal.Footer>
          {props.footer !== undefined ? (
            <>{props.footer}</>
          ) : (
            <>
              <Button variant="secondary" onClick={props.onNegativo}>
                {props.labelNegativo === undefined
                  ? "Cancelar"
                  : props.labelNegativo}
              </Button>
              {props.labelNeutro !== undefined &&
              props.onNeutro !== undefined ? (
                <Button variant="outline-primary" onClick={props.onNeutro}>
                  {props.labelNeutro}
                </Button>
              ) : (
                ""
              )}
              {props.onPositivo !== undefined ? (
                <Button variant="primary" onClick={props.onPositivo}>
                  {props.labelPositivo === undefined
                    ? "Salvar"
                    : props.labelPositivo}
                </Button>
              ) : (
                ""
              )}
            </>
          )}
        </Modal.Footer>
      ) : (
        <></>
      )}
    </Modal>
  );
}
