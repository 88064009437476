import React, { useState } from "react";
import useCollapse from "react-collapsed";
import { DualRing } from "react-spinners-css";
import { toast } from "react-toastify";
import api from "../../classes/api";
import { ICartProduct, IExtra } from "../../classes/interfaces";
import { round2string } from "../../classes/numbers";
import ShowModal from "../ShowModal";
import { Container, Label, InputStyled, ContainerPriceAmount, PriceContainer, AmountContainerLeft, ButtonAmount, LabelAmount, ButtonAdd, SectionModal, ContainerPrice, ListTitle, ContainerProduct, GroupContainer, LabelTitle, LabelTitleList, ImageDefault84, LabelPrice, AmountContainer, ButtonAmountExtra, LabelAmountExtra, LeftContainer, ContainerButtonList, ContainerExtra } from "./styles";

interface InputProps {
  amountFlavors: number;
  product: ICartProduct | null;
  onExtraExecute: Function | null;
}

export default function CollapsedProductExtra(props: InputProps) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const [state, setState] = useState({ status: '' });
  const [loading, setLoading] = React.useState(false);
  const [extras, setExtras] = useState<IExtra[] | null>();

  React.useEffect(() => {
    if (isExpanded && (!extras || extras === null)) {
      setLoading(true);
      api()
        .get(`/product-size/${props.product?.sizeId}/${props.product?.product_id}/extras`)
        .then((res) => {
          setLoading(false);
          //console.log(res);
          if (res.data === undefined || res.data.code === undefined) {
            toast(res.data, {
              type: "error",
              autoClose: 3000,
            });
            return;
          }
          if (res.data.code !== 200) {
            toast(res.data, {
              type: "error",
              autoClose: 3000,
            });
            return;
          }
          if (res.data.data) {
            const items = res.data.data as IExtra[];
            /*items.map((item) => {
              item.price = item.price / props.amountFlavors;
            });*/
            setExtras(items);
          }
        })
        .catch((error) => {
          setLoading(false);
          //setState({ status: "404", token: state.token });
          toast(error, {
            type: "error",
            autoClose: 3000,
          });
          return;
        });
    }
  }, [isExpanded])

  function doExtraExecute() {
    if (props.onExtraExecute && props.onExtraExecute !== null) {
      props.onExtraExecute();
    }
  }

  function moreExtra(extra: IExtra) {
    if (extra.max > 0 && extra.amount >= extra.max) {
      toast("Não é permitido mais desse item", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }

    extra.amount += 1;

    let searched = false;
    props.product?.extras.map((item) => {
      if (item.id === extra.id) {
        searched = true;
        item.amount = extra.amount;
      }
    });

    if (!searched) {
      props.product?.extras.push(extra);
    }

    doExtraExecute();
    setState({ status: "moreExtra" });
  }

  function lessExtra(extra: IExtra) {
    if (extra.amount <= 0) {
      return;
    }

    extra.amount -= 1;

    if (extra.amount <= 0) {
      const newExtras = props.product?.extras.filter((item) => item.id !== extra.id);
      props.product!.extras = newExtras || props.product!.extras;
    }

    doExtraExecute();
    setState({ status: "lessExtra" });
  }

  return (<>

    <ShowModal
      isOpen={loading}
      //setOpen={setIsOpen}
      //onRequestClose={closeModal}
      title={"Carregando..."}
      label=""
      centered={true}
      size="sm"
      document={
        <LeftContainer>
          <DualRing color="#00796B" />
        </LeftContainer>
      }
      isFooter={false}
    />

    <ContainerProduct {...getToggleProps()}>
      <GroupContainer>
        <LabelTitleList>{props.product?.product}</LabelTitleList>
      </GroupContainer>
      {isExpanded ? "-" : "+"}
    </ContainerProduct>
    <div {...getCollapseProps()}>
      {!extras || extras === null
        ? (
          <div className="content">
            <LabelAmountExtra>Produto não permite adicional</LabelAmountExtra>
          </div>
        )
        : (<div className="content">
          {extras!.map((extra) => (
            <ContainerButtonList
              key={extra.id}
            >
              <ContainerExtra>
                <GroupContainer>{extra.name}</GroupContainer>
              </ContainerExtra>
              <ContainerPrice>
                <LabelPrice>R$ {round2string(extra.price / props.amountFlavors)}</LabelPrice>
              </ContainerPrice>
              <AmountContainer>
                <ButtonAmountExtra onClick={() => lessExtra(extra)}>
                  -
                </ButtonAmountExtra>
                <LabelAmountExtra>{extra.amount}</LabelAmountExtra>
                <ButtonAmountExtra onClick={() => moreExtra(extra)}>
                  +
                </ButtonAmountExtra>
              </AmountContainer>
            </ContainerButtonList>
          ))}
        </div>
        )
      }
    </div>
  </>);
}
