import React from "react";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter,
  Switch,
  Route,
  useRouteMatch,
  useParams,
  Link,
} from "react-router-dom";
import StorePage from "../../pages/Store";
import CartPage from "../../pages/Cart";
import PaymentsPage from "../../pages/Payments";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export default function Companies() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:suffix/group/:groupId/products`}>
        <h3>Listagem de Produtos</h3>
      </Route>
      <Route path={`${match.path}/:suffix/cart`}>
        <CartPage />
      </Route>
      <Route path={`${match.path}/:suffix/payments`}>
        <PaymentsPage />
      </Route>
      <Route path={`${match.path}/:suffix`}>
        <StorePage />
      </Route>
      <Route path={`${match.path}`}>
        <h3>Listagem de empresas</h3>
      </Route>
    </Switch>
  );
}
