import {
  Container,
  Form,
  FormTitle,
  Section,
  LeftContainer,
  Label,
  ContainerProduct,
  ContainerButtonList,
  LabelPrice,
  GroupContainer,
  LabelTitleList,
  LabelPriceLeft,
  ContainerPriceLeft,
  ContainerOptions,
  ContainerOptionsBlock,
  Description,
  ImageCartDark,
  LineContainer,
  LineContainerRight,
  LabelTitle,
  RightContainer,
  ContainerTitle,
  ImageMap,
  ImagePhone,
  ContainerColumn,
  ButtonAll,
  ContainerPaddingDefault,
  ImageDefault84,
  ImageDefaultCircle,
  SectionModal,
} from "./styles";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import React, { Component, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";
import { teal } from "@material-ui/core/colors";
import Input from "../../components/Input";
import { ISale, ISaleProduct } from "../../classes/interfaces";
import api from "../../classes/api";
import { auth } from "../../classes/auth";
import ShowModal from "../../components/ShowModal";
import { FormControl, InputGroup } from "react-bootstrap";

export default function OrderPage() {
  const history = useHistory();
  let match = useRouteMatch();
  let { saleId } = useParams<{ saleId: string }>();
  const [state, setState] = React.useState({ status: "create", token: "" });
  const [sale, setSale] = React.useState<ISale>();
  const [motivo, setMotivo] = React.useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
      display: "block",
    },
    checked: {
      color: teal[400],
      "&$checked": {
        color: teal[600],
      },
      margin: 0,
      marginLeft: 10,
      padding: 0,
    },
  }));

  const classes = useStyles();

  function to(id: string) {
    const element = document.getElementById(id);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
      });
  }

  const getSale = (forcarCarregar: boolean = false) => {
    if (!sale || sale.products.length <= 0 || forcarCarregar) {
      api()
        .get(`/sale/${saleId}`)
        .then((res) => {
          //console.log(res);
          if (res.data === undefined || res.data.code === undefined) {
            setState({ status: "404", token: state.token });
          }
          if (res.data.code !== 200) {
            setState({ status: res.data.code, token: state.token });
          }
          setSale(res.data.data);
        })
        .catch((error) => {
          console.log(error);
          setState({ status: "404", token: state.token });
        });
    }
  };

  const getAuthorization = () => {
    if (state.token === "") {
      auth().then((token) => {
        if (token !== undefined && token !== "") {
          state.status = "allowed";
          state.token = token;
          setState({ status: "allowed", token: token });
          getSale();
        }
      });
    } else {
      getSale();
    }
  };

  useEffect(() => {
    getAuthorization();
  }, []);

  if (
    sale === undefined ||
    sale.products === undefined ||
    sale.products.length <= 0
  ) {
    return (
      <Container>
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <LineContainer>
            <LineContainer>
              <Section>
                <ImageCartDark />
                Carregando Meu Pedido...
              </Section>
            </LineContainer>
            <LineContainerRight></LineContainerRight>
          </LineContainer>
        </Form>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <ShowModal
          isOpen={modalIsOpen}
          //setOpen={setIsOpen}
          onRequestClose={closeModal}
          title="Deseja realmente cancelar a venda ?"
          label="Informe um motivo de cancelamento"
          document={
            <div>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Motivo</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="Ex.: Informei o endereço incorreto"
                  onChange={(ev) => {
                    setMotivo(ev.target.value);
                  }}
                />
              </InputGroup>
            </div>
          }
          onNegativo={closeModal}
          onPositivo={() => {
            if (motivo === null || motivo === "") {
              toast("É necessário informar o motivo de cancelamento", {
                type: "error",
                autoClose: 2000,
              });
              return;
            }

            api()
              .put(`/sale/${saleId}/cancel`, { reasonCancellation: motivo })
              .then((res) => {
                //console.log(res);
                if (res.data === undefined || res.data.code === undefined) {
                  setState({ status: "404", token: state.token });
                  return;
                }
                if (res.data.code !== 200) {
                  setState({ status: res.data.code, token: state.token });
                  return;
                }
                //setSale(res.data.data);
                sale.products = [];
                setSale(sale);
                getSale(true);
                setState({ status: res.data.code, token: state.token });
                closeModal();
              })
              .catch((error) => {
                console.log(error);
                toast(
                  "Impossível cancelar, pedido já confirmado. Entre em contato com o estabelecimento.",
                  {
                    type: "error",
                    autoClose: 2000,
                  }
                );
                sale.products = [];
                setSale(sale);
                getSale(true);
                setState({ status: "500", token: state.token });
                closeModal();
              });
          }}
        />

        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <LineContainer>
            <LineContainer>
              <Section>
                <ImageCartDark />
                Meu Pedido
              </Section>
            </LineContainer>
            <LineContainerRight>
              <Button variant="link" onClick={() => {
                //console.log(sale.store.suffix)
                if (sale.store.suffix) {
                  history.push(
                    match.path.replaceAll("order", "store").replaceAll(":saleId", sale.store.suffix)
                  );
                }
              }}>
                Voltar para a loja
              </Button>
            </LineContainerRight>
            {sale.status === "waiting" || sale.status === "integrated" ? (
              <LineContainerRight>
                <Button variant="danger" onClick={() => openModal()}>
                  Cancelar
                </Button>
              </LineContainerRight>
            ) : (
              <></>
            )}
          </LineContainer>

          <ContainerTitle>
            <LeftContainer>
              {sale.store.imageUrl !== null && sale.store.imageUrl !== "" ? (
                <img
                  src={sale.store?.imageUrl}
                  width="84px"
                  height="84px"
                  style={{
                    borderRadius: "50px",
                    marginLeft: "20px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <ImageDefaultCircle />
              )}
            </LeftContainer>

            <RightContainer>
              <FormTitle>{sale.store?.name}</FormTitle>
              <div>
                <Label>
                  <ImageMap />
                  {sale.store?.address +
                    ", " +
                    sale.store?.number +
                    " - " +
                    sale.store?.district +
                    //", " +
                    //sale.store?.zipCode +
                    " - " +
                    sale.store?.city?.name +
                    "/" +
                    sale.store?.city?.state}
                </Label>
              </div>
              <div>
                <Label>
                  <ImagePhone />
                  {sale.store?.phone}
                </Label>
              </div>
            </RightContainer>
          </ContainerTitle>

          <SectionModal>Resumo</SectionModal>
          <ContainerColumn>
            <div>{getStatus(sale.status || "")}</div>
            <Label>{getDateTime(sale.updateStatus || "")}</Label>
            {sale.reasonCancellation !== null &&
              sale.reasonCancellation !== "" ? (
              <Label>{"Motivo: " + sale.reasonCancellation}</Label>
            ) : (
              <></>
            )}
            <ContainerPaddingDefault></ContainerPaddingDefault>
            <ContainerOptions>
              <ContainerOptionsBlock>
                <Description>Total dos itens</Description>
                <LabelPrice>
                  R$ {((sale.total || 0) - (sale.tax || 0)).toFixed(2)}
                </LabelPrice>
              </ContainerOptionsBlock>
              <ContainerOptionsBlock>
                <Description>Taxa de Entrega</Description>
                <LabelPrice>R$ {(sale.tax || 0).toFixed(2)}</LabelPrice>
              </ContainerOptionsBlock>
              <ContainerOptionsBlock>
                <Description>Total do pedido</Description>
                <LabelPrice>R$ {(sale.total || 0).toFixed(2)}</LabelPrice>
              </ContainerOptionsBlock>
            </ContainerOptions>
          </ContainerColumn>

          {sale.deliveryType === "delivery" ? (
            <>
              <SectionModal>Endereço de entrega</SectionModal>
              <ContainerColumn>
                <ImageMap />
                {sale.deliveryAddress +
                  ", " +
                  sale.deliveryNumber +
                  " - " +
                  sale.deliveryDistrict +
                  ", " +
                  sale.deliveryZipCode +
                  " - " +
                  sale.deliveryCity?.name +
                  "/" +
                  sale.deliveryCity?.state || ""}
              </ContainerColumn>
            </>
          ) : (
            <>
              <ContainerColumn>
                <SectionModal>Vou Buscar</SectionModal>
              </ContainerColumn>
            </>
          )}

          <SectionModal>Produtos</SectionModal>

          <div className={classes.root}>
            {sale.products.map((product) => (
              <ContainerButtonList
              //key={product.id}
              //onClick={() => openModal(product)}
              >
                <ContainerProduct>
                  <FormTitle>{product.amount}</FormTitle>
                  <GroupContainer>
                    <LabelTitleList>{product.name}</LabelTitleList>
                    <LeftContainer>
                      {getAdicionais(product)}
                      {product.note && product.note !== "" ? (
                        <Label>{"Observação: " + product.note}</Label>
                      ) : (
                        ""
                      )}
                    </LeftContainer>
                    <ContainerPriceLeft>
                      <LabelPriceLeft>
                        R$ {product.total?.toFixed(2)}
                      </LabelPriceLeft>
                    </ContainerPriceLeft>
                  </GroupContainer>
                  {(product.imageUrl || "") !== "" ? (
                    <img
                      src={product.imageUrl}
                      width="84px"
                      height="84px"
                      style={{
                        borderRadius: "5px",
                        marginLeft: "10px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <ImageDefault84 />
                  )}
                </ContainerProduct>
              </ContainerButtonList>
            ))}
          </div>

          <SectionModal>Pagamentos</SectionModal>
          <ContainerTitle>
            <div className={classes.root}>
              {sale.payments.map((payment) => (
                <ContainerButtonList
                //key={product.id}
                //onClick={() => openModal(product)}
                >
                  <ContainerProduct>
                    <GroupContainer>
                      <LabelTitleList>{payment.name}</LabelTitleList>
                      <ContainerPriceLeft>
                        <LabelPriceLeft>
                          R$ {payment.value.toFixed(2)}
                        </LabelPriceLeft>
                      </ContainerPriceLeft>
                    </GroupContainer>
                  </ContainerProduct>
                </ContainerButtonList>
              ))}
            </div>
          </ContainerTitle>

          <SectionModal>Etapas dos status</SectionModal>
          <ContainerTitle>
            <div className={classes.root}>
              {sale.statuses?.map((status) => (
                <ContainerButtonList
                //key={product.id}
                //onClick={() => openModal(product)}
                >
                  <ContainerProduct>
                    <GroupContainer>
                      <LabelTitleList>
                        {getStatuses(status.status)}
                      </LabelTitleList>
                      <LeftContainer>
                        <Label>{getDateTime(status.createdAt)}</Label>
                      </LeftContainer>
                    </GroupContainer>
                  </ContainerProduct>
                </ContainerButtonList>
              ))}
            </div>
          </ContainerTitle>

          {(sale.store.cellPhone || "") !== "" ? (
            <ButtonAll onClick={() => {
              //let text = encodeURIComponent(`👩‍🍳 *Pedido feito com MenuChef* 👩‍🍳`) + '%0A';
              let text = encodeURIComponent(`*Pedido feito com MenuChef*`) + '%0A';

              sale.products.map((product) => {
                text += `%0A%2A${product.amount}x%2A%20%2D%20${encodeURIComponent(product.name || "")}`;
              });

              text += `%0A%0A${encodeURIComponent(`*Nome:* ${sale.deliveryName || ''}`)}`;

              if (sale.deliveryType === "delivery") {
                text += `%0A${encodeURIComponent(`*Valor Entrega:* R\$${(sale.tax || 0).toFixed(2)}`)}`;
              }
              text += `%0A${encodeURIComponent(`*Total:* R\$${(sale.total || 0).toFixed(2)}`)}`;

              if (sale.payments.length === 1) {
                if ((sale.payments[0].changeFor || 0) > 0) {
                  text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Não')}`;
                  text += `%0A${encodeURIComponent(`*Troco para:* ${(sale.payments[0].changeFor || 0).toFixed(2)}`)}%0A`;
                }
                else if ((sale.payments[0].name) && (sale.payments[0].name.trim().toUpperCase() === "DINHEIRO")) {
                  text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Não')}`;
                }
                else {
                  text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Sim')}`;
                }
              } else {
                text += `%0A%0A${encodeURIComponent('*Levar Cartão:* Sim')}`;
              }

              text += `%0A%0A${encodeURIComponent('*Link para acompanhar o pedido:*')}%0A`;
              text += `http://menuchef.com.br${match.url}`;
              var win = window.open(`https://wa.me/55${(sale.store.cellPhone || "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")}?text=${text}`, '_blank');
              if (win) {
                win.focus();
              }
            }}>
              Compartilhar com a loja
            </ButtonAll>) : (<></>)}
        </Form>
      </Container>
    </>
  );
}

function getAdicionais(product: ISaleProduct) {
  let stepId = "";
  let prefix = "";
  return product.extras.map((extra) => {
    if (stepId !== (extra.step?.id || "-1")) {
      stepId = extra.step?.id || "-1";
      prefix = extra.step?.name || "Adicionais";
      prefix += ":";
      return (
        <>
          <LabelTitle>{prefix}</LabelTitle>
          {product.extras.map((ex) => {
            if ((ex.step?.id || "-1") === stepId) {
              return (
                <Label>{ex.amount.toString() + "X " + ex.extra.name}</Label>
              );
            }
          })}
        </>
      );
    }
  });
}

function getStatus(status: string) {
  if (status === "confirmed") {
    return "Pedido Confirmado";
  }
  if (status === "denied") {
    return "Pedido Cancelado pelo Estabelecimento";
  }
  if (status === "canceled") {
    return "Pedido Cancelado";
  }
  if (status === "ready") {
    return "Seu Pedido está Pronto para a Retirada";
  }
  if (status === "delivering") {
    return "Seu pedido Saiu para a Entrega";
  }
  if (status === "concluded") {
    return "Seu pedido foi Concluído";
  }
  return "Aguardando confirmação";
}

function getStatuses(status: string) {
  if (status === "confirmed") {
    return "Confirmado";
  }
  if (status === "denied") {
    return "Cancelado pelo Estabelecimento";
  }
  if (status === "canceled") {
    return "Cancelado pelo Cliente";
  }
  if (status === "ready") {
    return "Pronto para Retirada";
  }
  if (status === "delivering") {
    return "Saiu para a Entrega";
  }
  if (status === "concluded") {
    return "Concluído";
  }
  if (status === "integrated") {
    return "Integrado";
  }
  return "Aguardando confirmação";
}

function padLeadingZeros(num: number, size: number) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

function getDateTime(value: string) {
  const dateTime = new Date(value || "");
  if (dateTime) {
    return (
      padLeadingZeros(dateTime.getDate(), 2) +
      "/" +
      padLeadingZeros(dateTime.getMonth() + 1, 2) +
      "/" +
      padLeadingZeros(dateTime.getFullYear(), 4) +
      " " +
      padLeadingZeros(dateTime.getHours(), 2) +
      ":" +
      padLeadingZeros(dateTime.getMinutes(), 2)
    );
  }
  return "";
}
