import React from "react";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import HomePage from "./pages/Home";
import NewPage from "./pages/New";
import CompaniesPage from "./pages/Companies";
import StorePage from "./pages/Store";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SaleTerminatePage from "./pages/SaleTerminate";
import OrderPage from "./pages/Order";
import CartPage from "./pages/Cart";
import Payments from "./pages/Payments";

function App() {
  const theme = {
    primary: "#322153",
    secondary: "#28ca91",
    background: "#F0F0F5",
    text: "#263238",
    label: "#9E9E9E",
    white: "#fff",
    button: "#00796B",
    cancel: "#D32F2F",
    shadow: "#E0E0E0",
    accent: "#1976D2",
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/new">
            <NewPage />
          </Route>
          <Route path="/store">
            <CompaniesPage />
          </Route>
          <Route path="/store/:companyId/group/:groupId/products">
            <NewPage />
          </Route>
          <Route path="/terminate/:saleId">
            <SaleTerminatePage />
          </Route>
          <Route path="/order/:saleId">
            <OrderPage />
          </Route>
          <Route path="/:suffix/table/:tableId/cart">
            <CartPage />
          </Route>
          <Route path="/:suffix/table/:tableId">
            <StorePage />
          </Route>
          <Route path="/:suffix/cart">
            <CartPage />
          </Route>
          <Route path="/:suffix/payments">
            <Payments />
          </Route>
          <Route path="/:suffix">
            <StorePage />
          </Route>
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
